export default {
    language: '语言',
    home: '首页',
    articleArchive: '文章归档',
    articleCategory: '文章分类',
    articleTag:'文章标签',
    sponsor: '赞助',
    moment: '瞬间',
    forum: '论坛',
    say: '说说',
    tipsoon: '简讯',
    navigation: '网址导航',
    hot: '热搜',
    message: '留言板',
    friendLink: '友情链接',
    about: '关于本站',
    sourceCode: '网站源码',
    rms: '后台管理',
    compose: '创作',
    publishArticle: '发布文章',
    publishSay: '发布说说',
    publishForum: '发布说说',
    noticeList: ["系统通知", "评论", "关注", "点赞", "收藏", "私信"],
    loginTips: ["登录网站，获取以下权益", "参与互动评论", "发表优质文章"],
    login: '立即登录',
    userCenter: '用户中心',
    logout: '退出登录'
}
